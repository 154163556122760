import moment from 'moment-timezone'
import palette from 'google-palette'
import { amber, grey, green } from '@mui/material/colors'
import { COHORT_BREAKDOWN_KEY, CURRENCY_DEFAULT, CURRENCY_SYMBOL_MAP, DATETIME_RESOLUTION_MAP, DAY_RESOLUTION_MAP, SEGMENT_COLOR_PALETTE } from '../constants'

export function getColorPalette(size) {
  const colors = palette('tol-rainbow', size)
  return colors
}

export function getNextAvailableColor(segments, secondaryThemeColor=null) {
  // Get an array of all colors currently in use
  const colorsInUse = segments.reduce((colors, segment) => {
    const segmentColors = Object.values(segment.breakdownState).map(obj => obj.color)
    colors.push(segment.color, ...segmentColors)
    return colors
  }, [])

  let segmentColorPalette = [...SEGMENT_COLOR_PALETTE]
  if (secondaryThemeColor) {
    segmentColorPalette = [secondaryThemeColor, ...segmentColorPalette]
  }

  // Find the first color in the palette that is not in use
  const availableColor = segmentColorPalette.find(color => !colorsInUse.includes(color))

  // If all colors are in use, default to the first color in the palette
  return availableColor || segmentColorPalette[0]
}

export function calculateChange(currentValue, previousValue) {
  let change = (currentValue - previousValue) / previousValue
  if (isNaN(change)) change = 0
  return change
}

export function formatNumber(amount, decimalCount=2, hideIntegerDecimals=false, abbreviateLargeAmounts=false, decimal='.', thousands=',', currency=null) {
  const currencyPrefix = currency ? CURRENCY_SYMBOL_MAP[currency] || CURRENCY_SYMBOL_MAP[CURRENCY_DEFAULT] : ''
  const thousandsThreshold = 1e3
  const millionsThreshold = 1e6
  const thousandsSuffix = 'k'
  const millionsSuffix = 'm'
  if (isNaN(amount)) return `-`
  if (!isFinite(amount)) return  `-`
  try {
    let thisAmount = amount
    let thisDecimalCount = decimalCount
    let isThousands = Math.abs(amount) >= thousandsThreshold && Math.abs(amount) < millionsThreshold
    let isMilliions = Math.abs(amount) >= millionsThreshold
    if (abbreviateLargeAmounts) {
      if (isThousands) {
        thisAmount = amount / thousandsThreshold
        thisDecimalCount = 1
      } else if (isMilliions) {
        thisAmount = amount / millionsThreshold
        thisDecimalCount = 1
      }
    }

    thisDecimalCount = Math.abs(thisDecimalCount)
    thisDecimalCount = isNaN(thisDecimalCount) ? 2 : thisDecimalCount

    const negativeSign = thisAmount < 0 ? '-' : ''

    let i = parseInt(thisAmount = Math.abs(Number(thisAmount) || 0).toFixed(thisDecimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0
    let k = decimal + Math.abs(thisAmount - i).toFixed(thisDecimalCount).slice(2)

    let decimalString = thisDecimalCount ? decimal + Math.abs(thisAmount - i).toFixed(thisDecimalCount).slice(2) : ''
    if (hideIntegerDecimals && thisDecimalCount === 1 && k === '.0') decimalString = ''
    if (hideIntegerDecimals && thisDecimalCount === 2 && k === '.00') decimalString = ''
    let suffix = ''
    if (abbreviateLargeAmounts) {
      if (isThousands) {
        suffix = thousandsSuffix
      } else if (isMilliions) {
        suffix = millionsSuffix
      }
    }

    return negativeSign + currencyPrefix + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + decimalString + suffix
  } catch (e) {
    console.log(e)
  }
}

export function groupByKey(arrayOfObjects, key) {
  return arrayOfObjects.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function shortenString(string, length) {
  if (string === null || string === undefined) return

  if (string.length >= length) {
    return string.substr(0, length - 1) + '...'
  }
  return string
}

export function capitalizeFirstLetter(string) {
  if (!string || string.length === 0) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function stableSort(array, order, orderBy) {
  const comparator = getComparator(order, orderBy)
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getAlias(aliases, key) {
  if (!aliases) return null
  // eslint-disable-next-line eqeqeq
  const match = aliases.find(alias => alias.id == key)
  if (match) {
    return match.name
  } else {
    return null
  }
}

export function getAliasFunnel(aliases, key) {
  if (!aliases) return null
  // eslint-disable-next-line eqeqeq
  const match = aliases.find(alias => alias.id == key)
  if (match) {
    return match.funnel_nickname
  } else {
    return null
  }
}

export function getAliasPlatform(aliases, key) {
  if (!aliases) return null
  // eslint-disable-next-line eqeqeq
  const match = aliases.find(alias => alias.id == key)
  if (match) {
    return match.platform
  } else {
    return null
  }
}

export function getBreakdownAliasPlatformId(aliases, breakdownField, breakdownKey) {
  return aliases?.[breakdownField]?.[breakdownKey]?.platform_id || breakdownKey
}

export function getBreakdownAlias(aliases, breakdownField, breakdownKey) {
  return aliases?.[breakdownField]?.[breakdownKey]?.name || breakdownKey
}

export function getBreakdownAliasSecondary(aliases, breakdownField, breakdownKey) {
  const aliasObject = aliases?.[breakdownField]?.[breakdownKey]
  if (!aliasObject) return null

  switch (breakdownField) {
    case 'product_id':
      return `${aliasObject.platform} > ${aliasObject.funnel_nickname}`
    case 'funnel_id':
      return `${aliasObject.platform}`
    case 'integration_id':
      return `${aliasObject.platform}`
    default:
      return null
  }
}

export function fileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export function getFrequencyFromUnixCronSchedule(unixCronSchedule) {
  let frequency
  switch (unixCronSchedule) {
    case '0 * * * *':
      frequency = 'hourly'
      break
    case '0 0 * * *':
      frequency = 'daily'
      break
    case '0 0 * * 1':
      frequency = 'weekly'
      break
    case '0 0 1 * *':
      frequency = 'monthly'
      break
    default:
      console.log(`unrecognized unix cron schedule: ${unixCronSchedule}`)
      throw new Error('UNREGONIZED_UNIX_CRON_SCHEDULE')
  }
  return frequency
}

export function getUnixCronScheduleFromFrequency(frequency) {
  // Frequency: hourly, daily, weekly, monthly
  let unixCronSchedule
  switch (frequency) {
    case 'hourly':
      unixCronSchedule = '0 * * * *'
      break
    case 'daily':
      unixCronSchedule = '0 0 * * *'
      break
    case 'weekly':
      unixCronSchedule = '0 0 * * 1'
      break
    case 'monthly':
      unixCronSchedule = '0 0 1 * *'
      break
    default:
      console.log(`unrecognized frequency: ${frequency}`)
      throw new Error('UNREGONIZED_FREQUENCY')
  }
  return unixCronSchedule
}

export function getChartData(segments, segmentsData, segmentsBreakdownData, xKey, yKey, endDate, timezone, config, returnActiveOnly=false, emptyDataRule='empty', cumulative=false) {
  // Create an object where each key is a xKey value and each value is an object that will contain the data for all segments and breakdowns at that xKey value.
  // This is the "flat" structure expected by recharts.
  const dataByXKey = segments.reduce((acc, segment, segmentIndex) => {
    // Get chart data for the segment
    const dataForSegment = segmentsData[segmentIndex].data?.chart || []
    const breakdownDataForSegment = segmentsBreakdownData[segmentIndex].data?.chart || []
    const breakdownKeysForSegment = segmentsBreakdownData[segmentIndex].data?.breakdownKeys || []

    dataForSegment.forEach((dataPoint, dataIndex) => {
      const xKeyValue = dataPoint[xKey]
      const previousDataPoint = dataIndex > 0 ? dataForSegment[dataIndex - 1] : null
      const previousXKeyValue = previousDataPoint ? previousDataPoint[xKey] : null

      // Add xKey to the object if it doesn't exist
      if (!acc[xKeyValue]) {
        acc[xKeyValue] = {
          [xKey]: xKeyValue,
        }
      }

      // If the segment is not loading, we can add the data based on the active criteria
      if (!segmentsData[segmentIndex].isLoading) {
        if (!returnActiveOnly || (returnActiveOnly && segment.isActive)) {
          let yValue = dataPoint[yKey]

          // Set yValue for non-cumulative case, or for the first data point of the cumulative case
          if (!cumulative || (cumulative && dataIndex === 0)) {
            // If yKey exists in the data point, set it
            if (yValue !== undefined && yValue !== null) {
              acc[xKeyValue][segment.id] = yValue
            }
            // Otherwise, set it based on the emptyDataRule
            else {
              acc[xKeyValue][segment.id] = handleEmptyData(dataIndex, yKey, null, dataForSegment, emptyDataRule)
            }
          }
          // Accumulate yValue by adding it to the previous data point's yValue
          else {
            const previousYValue = acc[previousXKeyValue][segment.id]

            // If the yValue exists, add it to the previousYValue
            if (yValue !== undefined && yValue !== null) {
              acc[xKeyValue][segment.id] = yValue + previousYValue
            }
            // If the yValue doesn't exist, set value as the previousYValue
            else {
              acc[xKeyValue][segment.id] = previousYValue
            }
          }
        }
      }

      if (segment.breakdown) {
        // Find corresponding breakdowns for this data point in the segment
        let breakdowns = segmentsBreakdownData.find(d => segment.id === d.data?.segmentId)?.data.chart[dataIndex].breakdowns || {}

        breakdownKeysForSegment.forEach(breakdownKey => {
          const breakdown = breakdowns[breakdownKey]
          const comboKey = getSegmentBreakdownComboKey(segment.id, breakdownKey)

          // If we are only returning active data and the breakdown is not active, skip it
          if (returnActiveOnly && !segment.breakdownState[breakdownKey]?.isActive) return

          // If this is a cohort breakdown, check if the xKey (day) is in the day range of the cohort,
          // and if not, skip it
          if (isCohortBreakdown(segment.breakdown)) {
            const cohortDayRange = getDateRangeDayCount(breakdownKey, endDate, timezone, config.extendBackend)
            // Check if this xKey (day) value is in the range of the cohort,
            // and if not, leave it empty
            if (xKeyValue > cohortDayRange) return acc
          }

          // Set yValue for non-cumulative case, or for the first data point of the cumulative case
          if (!cumulative || (cumulative && dataIndex === 0)) {
            // If breakdown exists, set the data
            if (breakdown) {
              acc[xKeyValue][comboKey] = breakdown[yKey]
            }
            // If breakdown doesn't exist, set it based on the emptyDataRule
            else {
              acc[xKeyValue][comboKey] = handleEmptyData(dataIndex, yKey, breakdownKey, breakdownDataForSegment, emptyDataRule)
            }
          }
          else {
            const previousYValue = acc[previousXKeyValue][comboKey]

            // If the yValue exists, add it to the previousYValue
            if (breakdown) {
              acc[xKeyValue][comboKey] = breakdown[yKey] + previousYValue
            }
            // If the yValue doesn't exist, set value as the previousYValue
            else {
              acc[xKeyValue][comboKey] = previousYValue
            }
          }
        })
      }
    })

    return acc
  }, {})

  // Convert the dataByXKey object into an array of objects
  const data = Object.values(dataByXKey)
  return data
}

export function getScorecardData(segments, segmentsData, cards, xKey, emptyDataRule='zero') {
  // If no cards are provided, return
  if (cards.length === 0) return null

  // If there aren't any segments for some reason, return
  if (segments.length === 0) return null

  // If there is no chartData, return null
  if (!segmentsData || segmentsData.length == 0) return null

  // For scorecards, there is only one segment, so get chartData for the first segment
  const chartData = segmentsData[0].data?.chart
  if (!chartData) return null

  // Scorecard chart data mirrors the structure of chartData, but replaces
  // the y values with the scorecard data for each card
  let scorecardChartData = []
  chartData.forEach((dataPoint, dataIndex) => {
    let scorecardDataPoint = {
      [xKey]: dataPoint[xKey],
    }
    cards.forEach(card => {
      let value = dataPoint[card.metricKey]
      if (!value) {
        value = handleEmptyData(dataIndex, card.metricKey, null, chartData, emptyDataRule)
      }
      const score = getCardMetricScoreByType(dataPoint, dataIndex, card, chartData)
      const change = getCardMetricChangeFromPrevious(dataPoint, dataIndex, card, chartData)
      scorecardDataPoint[card.metricKey] = { value, score, change }
    })
    scorecardChartData.push(scorecardDataPoint)
  })

  // For each metric, add up the number of green, yellow, and red scores
  const emptySummaryData = {
    greenCount: 0,
    greenPercentage: 0,
    yellowCount: 0,
    yellowPercentage: 0,
    redCount: 0,
    redPercentage: 0,
    totalCount: 0,
    score: '',
  }

  let scorecardSummaryData = {
    overall: { ...emptySummaryData },
    cards: {},
  }
  cards.forEach(card => {
    // Initialize an object for the metric
    let cardSummaryData = { ...emptySummaryData }

    // Go through the scorecardChartData, and count the number of
    // green, yellow, and red scores for the card's metric
    scorecardChartData.forEach(dataPoint => {
      // Increment the total count for the metric and overall
      cardSummaryData.totalCount++
      scorecardSummaryData.overall.totalCount++

      // Get the score for the metric and increment the count for the score
      const score = dataPoint[card.metricKey].score
      switch (score) {
        case 'green':
          cardSummaryData.greenCount++
          scorecardSummaryData.overall.greenCount++
          break
        case 'yellow':
          cardSummaryData.yellowCount++
          scorecardSummaryData.overall.yellowCount++
          break
        case 'red':
          cardSummaryData.redCount++
          scorecardSummaryData.overall.redCount++
          break
      }
    })

    // Calculate the percentage of green, yellow, and red scores
    cardSummaryData.greenPercentage = cardSummaryData.greenCount / cardSummaryData.totalCount
    cardSummaryData.yellowPercentage = cardSummaryData.yellowCount / cardSummaryData.totalCount
    cardSummaryData.redPercentage = cardSummaryData.redCount / cardSummaryData.totalCount

    // Set the overall score of the card based on which score has the highest count.
    // Tie goes to green, then yellow
    const maxCount = Math.max(
      cardSummaryData.greenCount,
      cardSummaryData.yellowCount,
      cardSummaryData.redCount,
    )
    if (maxCount === cardSummaryData.greenCount) {
      cardSummaryData.score = 'green'
    } else if (maxCount === cardSummaryData.yellowCount) {
      cardSummaryData.score = 'yellow'
    } else if (maxCount === cardSummaryData.redCount) {
      cardSummaryData.score = 'red'
    }

    // Assign cardSummaryData to the card metricKey in the scorecardSummaryData object
    scorecardSummaryData.cards[card.metricKey] = cardSummaryData
  })

  // Calculate the percentage of green, yellow, and red scores
  scorecardSummaryData.overall.greenPercentage = scorecardSummaryData.overall.greenCount / scorecardSummaryData.overall.totalCount
  scorecardSummaryData.overall.yellowPercentage = scorecardSummaryData.overall.yellowCount / scorecardSummaryData.overall.totalCount
  scorecardSummaryData.overall.redPercentage = scorecardSummaryData.overall.redCount / scorecardSummaryData.overall.totalCount

  // Set the overall score of the card based on which score has the highest count.
  // Tie goes to green, then yellow
  const maxCount = Math.max(
    scorecardSummaryData.overall.greenCount,
    scorecardSummaryData.overall.yellowCount,
    scorecardSummaryData.overall.redCount,
  )
  if (maxCount === scorecardSummaryData.overall.greenCount) {
    scorecardSummaryData.overall.score = 'green'
  } else if (maxCount === scorecardSummaryData.overall.yellowCount) {
    scorecardSummaryData.overall.score = 'yellow'
  } else if (maxCount === scorecardSummaryData.overall.redCount) {
    scorecardSummaryData.overall.score = 'red'
  }

  return {
    summary: scorecardSummaryData,
    chart: scorecardChartData
  }
}

const getCardMetricScoreByType = (dataPoint, dataIndex, card, chartData) => {
  const value = dataPoint[card.metricKey] || 0
  switch (card.scoreType) {
    case 'overunder':
      if (value > card.scoreValueOverUnder) return 'green'
      if (value ===  card.scoreValueOverUnder) return 'yellow'
      if (value < card.scoreValueOverUnder) return 'red'
      break
    case 'stoplight':
      if (value >= card.scoreValueStoplightGreen) return 'green'
      if (value >= card.scoreValueStoplightYellow) return 'yellow'
      if (value < card.scoreValueStoplightYellow) return 'red'
      break
    case 'previous': {
      // Get the previous data point with a value for the metricKey
      const previousValue = dataIndex > 0 ? chartData[dataIndex - 1][card.metricKey] || 0 : 0
      if (value > previousValue) return 'green'
      if (value === previousValue) return 'yellow'
      if (value < previousValue) return 'red'
      break
    }
  }
}

const getCardMetricChangeFromPrevious = (dataPoint, dataIndex, card, chartData) => {
  const currentValue = dataPoint[card.metricKey] || 0
  const previousValue = dataIndex > 0 ? chartData[dataIndex - 1][card.metricKey] : 0
  return calculateChange(currentValue, previousValue)
}

export function getTargetData(segments, chartData, targetValue, xKey, startDate, endDate, timezone) {
  // If no targetValue is provided, return
  if (!targetValue) return null

  // If there aren't any segments for some reason, return
  if (segments.length === 0) return null

  // If there is no chartData, return null
  if (!chartData) return null

  // For targets, there is only one segment. Get its ID and find
  // the max value of the chartData to compare to the targetValue
  const segmentId = segments[0].id

  // Find the max value
  const maxValue = Math.max(...chartData.map(item => item[segmentId] || 0))

  // Find the first occurrence of the max value
  const maxItem = chartData.find(item => item[segmentId] === maxValue)

  // Get the xKey value where the max value occurs
  const maxDateTime = maxItem ? maxItem[xKey] : null

  // Get the number of remaining days in the target period, which is the difference
  // between today and the endDate
  const daysTotal = moment.tz(endDate, timezone).diff(moment.tz(startDate, timezone), 'days') + 1
  // daysElapsed cannot exceed daysTotal
  const hoursSinceStart = moment.tz(timezone).diff(moment.tz(startDate, timezone), 'hours')
  const daysSinceStart = hoursSinceStart / 24
  const daysElapsed = Math.min(daysTotal, daysSinceStart)
  const daysRemaining = daysTotal - daysElapsed

  // Get the difference between the targetValue and the maxValue
  // to see how much is left to hit the target.
  // If the maxValue is greater than the target, then the target has already
  // been hit, so return null to indicate this.
  // if (maxValue >= targetValue) return null

  const valueRemaining = Math.max(targetValue - maxValue, 0)

  // Calculate the percentage progress towards the target
  const valuePercentage = maxValue / targetValue

  // Calculate the percentage of time that has passed towards the target
  const dateTimePercentage = daysElapsed / daysTotal

  // Based on the days remaining and value remaining,
  // calculate the daily, weekly, and monthly average value
  // needed to hit the target in the remaining days.
  // If the target has already been hit, return null to indicate this.
  const dailyAverage = valueRemaining / daysRemaining || 0
  const weeklyAverage = valueRemaining / (daysRemaining / 7) || 0
  const monthlyAverage = valueRemaining / (daysRemaining / 30) || 0

  // Get the current daily average to compute the projected end value
  const dailyAverageCurrent = maxValue / daysElapsed || 0
  const projectedEndValue = maxValue + (dailyAverageCurrent * daysRemaining)

  return {
    maxValue,
    maxDateTime,
    daysElapsed,
    daysRemaining,
    daysTotal,
    valueRemaining,
    valuePercentage,
    dateTimePercentage,
    dailyAverage,
    weeklyAverage,
    monthlyAverage,
    projectedEndValue,
  }
}

export function getTargetReferenceLines(targetData, targetValue, xKey, startDate, endDate, timezone, resolution) {
  if (!targetData) return []

  let referenceLines = []

  // Add vertical reference line representing today's date if it's in the range
  const today = moment.tz(timezone)
  const momentResolution = resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[resolution]

  // NOTE: because these lines are drawn as SVGs, the order matters.
  // Later lines are drawn on top of earlier lines.

  if (today.isBetween(moment.tz(startDate, timezone), moment.tz(endDate, timezone), momentResolution, '[]')) {
    const endDateMoment = moment.tz(endDate, timezone)

    // If the target hasn't been hit yet, draw a line from
    // the current value to the target value
    if (targetData.maxDateTime && targetData.valueRemaining > 0) {
      referenceLines.push(
        {
          key: 'remaining',
          type: 'segment',
          value: {
            xStart: today.startOf(momentResolution).format(),
            yStart: targetData.maxValue,
            xEnd: endDateMoment.startOf(momentResolution).format(),
            yEnd: targetValue,
          },
          label: null,
          stroke: green[500],
          strokeWidth: 1,
          strokeDasharray: '4 2',
        }
      )
    }

    // Draw line to the projected value
    if (targetData.maxDateTime) {
      referenceLines.push(
        {
          key: 'projectionLine',
          type: 'segment',
          value: {
            xStart: today.startOf(momentResolution).format(),
            yStart: targetData.maxValue,
            xEnd: endDateMoment.startOf(momentResolution).format(),
            yEnd: targetData.projectedEndValue,
          },
          label: null,
          stroke: targetData.projectedEndValue >= targetValue ? green[800] : amber[500],
          strokeWidth: 1,
          strokeDasharray: '4 2',
        }
      )
    }

    // Find the xKey in chartData that corresponds to today
    referenceLines.push({
        key: 'today',
        type: 'x',
        value: today.startOf(momentResolution).format(),
        label: 'Now',
        stroke: grey[400],
        strokeWidth: 1,
        strokeDasharray: '4 2',
      }
    )
  }

  // Add horizontal reference line representing the target value
  referenceLines.push(
    {
      key: 'target',
      type: 'y',
      value: targetValue,
      label: 'Target',
      stroke: green[500],
      strokeWidth: 3,
      strokeDasharray: '4 2',
    }
  )

  // Add horizontal reference line representing the project end value at current pace,
  // only shown if the target period isn't over yet
  if (targetData.dateTimePercentage < 1) {
    referenceLines.push(
      {
        key: 'projectedValue',
        type: 'y',
        value: targetData.projectedEndValue,
        label: 'Projected',
        stroke: targetData.projectedEndValue >= targetValue ? green[800] : amber[500],
        strokeWidth: 3,
        strokeDasharray: '4 2',
      }
    )
  }

  return referenceLines
}

export function handleEmptyData(dataIndex, yKey, breakdownKey, chartData, emptyDataRule='empty') {
  switch (emptyDataRule) {
    case 'empty':
      return undefined
    case 'zero':
      return 0
    case 'previous': {
      // Find the previous data point with a value for the yKey
      let previousDataPointIndex = dataIndex - 1
      let previousDataPoint = chartData[previousDataPointIndex]
      let previousYValue = breakdownKey ? previousDataPoint.breakdowns[breakdownKey]?.[yKey] : previousDataPoint?.[yKey]
      while (previousDataPointIndex >= 0 && previousYValue === undefined) {
        previousDataPointIndex--
        previousDataPoint = chartData[previousDataPointIndex]
        previousYValue = breakdownKey ? previousDataPoint.breakdowns[breakdownKey]?.[yKey] : previousDataPoint?.[yKey]
      }
      return previousYValue
    }
    default:
      return undefined
  }
}

export function getSummaryTableData(segments, segmentsData, segmentsBreakdownData) {
  // Create an object where each key is a segment id and each value is an object that will contain the summary data for that segment
  const dataBySegment = segments.reduce((acc, segment, index) => {
    const summaryForSegment = segmentsData[index].isLoading ? null : segmentsData[index].data.summary
    const summaryBreakdownsForSegment = segmentsData[index].isLoading ? null : segmentsBreakdownData.find(d => segment.id === d.data?.segmentId)?.data.summary.breakdowns || {}

    acc[segment.id] = {...summaryForSegment, breakdowns: summaryBreakdownsForSegment}

    return acc
  }, {})

  // Convert the dataBySegment object into an array of objects
  const data = Object.values(dataBySegment)

  return data
}

export function getSegmentById(segments, id) {
  return segments.find(segment => segment.id === id)
}

export function getSegmentDisplayName(segment, savedSegments) {
  if (segment.filters.length === 0 && segment.segmentFilters.length === 0) return 'All Data'

  let displayNameParts = []

  // Add segment filters
  segment.segmentFilters.forEach(filter => {
    const savedSegment = savedSegments.find(s => s.id === filter.id)
    if (savedSegment) {
      displayNameParts.push(`${savedSegment.name}`)
    }
  })

  // Add group and item filters
  segment.filters.forEach(filter => {
    let filterDisplayName = `${filter.fieldName} ${filter.operator.name}`
    if (filter.groupNames.length > 0) {
      const groupNamesWithPrefix = filter.groupNames.map(name => `${name}`)
      filterDisplayName += ` ${groupNamesWithPrefix.join(' | ')}`
    }
    if (filter.itemNames.length > 0) {
      filterDisplayName += filter.groupNames.length > 0 ? ' | ' : ''
      filterDisplayName += ` ${filter.itemNames.join(' | ')}`
    }
    displayNameParts.push(`${filterDisplayName}`)
  })

  return displayNameParts.join(' • ').trim()
}

export function getSegmentBreakdownComboKey(segmentId, breakdown) {
  return `breakdown-${segmentId}-${breakdown}`
}

export function getGroupDisplayName(groupId, savedGroups) {
  const group = savedGroups.find(g => g.id === groupId)

  let displayName = `${group.filter.fieldName} ${group.filter.operator.name}`
  if (group.filter.groupNames.length > 0) {
    const groupNamesWithPrefix = group.filter.groupNames.map(name => `${name}`)
    displayName += ` ${groupNamesWithPrefix.join(' | ')}`
  }
  if (group.filter.itemNames.length > 0) {
    displayName += group.filter.groupNames.length > 0 ? ' | ' : ''
    displayName += ` ${group.filter.itemNames.join(' | ')}`
  }

  return displayName
}

export function transformChartDataToPercentages(chartData, X_KEY) {
  return chartData.map((dataPoint) => {
    // Calculate the sum of all values except for the X_KEY
    const sum = Object.keys(dataPoint).reduce((acc, key) => {
      if (key !== X_KEY) {
        return acc + dataPoint[key]
      }
      return acc
    }, 0)

    // Create a new object where each value is the percentage of the sum
    const newDataPoint = {}
    for (const key in dataPoint) {
      if (key !== X_KEY) {
        newDataPoint[key] = sum !== 0 ? (dataPoint[key] / sum) : 0
      } else {
        newDataPoint[key] = dataPoint[key]
      }
    }

    return newDataPoint
  })
}

export function getMomentFormatFromResolution(resolution) {
  switch (resolution) {
    case 0:
    case 1:
      return 'MMM DD, YY'
    case 2:
      return 'MMM YYYY'
    case 3:
      return '[Q]Q YYYY'
    case 4:
      return 'YYYY'
    default:
      return 'MMM DD, YY'
  }
}

export function getDisplayValueByFormat(value, format, options={}) {
  switch (format) {
    case 'string':
      return value ? value.toString() : ''
    case 'currency':
      return formatNumber(value, options.decimalCount, options.hideIntegerDecimals, options.abbreviateLargeAmounts, undefined, undefined, options.currency || CURRENCY_DEFAULT)
    case 'percent':
      return `${formatNumber(value*100, options.decimalCount, options.hideIntegerDecimals, options.abbreviateLargeAmounts, undefined, undefined, null)}%`
    case 'integer':
      return formatNumber(value, 0, options.hideIntegerDecimals, options.abbreviateLargeAmounts, undefined, undefined, null)
    case 'decimal':
      return formatNumber(value, options.decimalCount, options.hideIntegerDecimals, options.abbreviateLargeAmounts, undefined, undefined, null)
    case 'date_time':
      if (options.xKeyAsRange) {
        const momentResolution = options.resolution === 1 ? 'isoWeek' : DATETIME_RESOLUTION_MAP[options.resolution]
        const start = moment.tz(value, options.timezone).format(getMomentFormatFromResolution(0))
        const end = moment.tz(value, options.timezone).add(1, momentResolution).subtract(1, 'day').format(getMomentFormatFromResolution(0))
        return `${start}-${end}`
      }
      return moment.tz(value, options.timezone).format(getMomentFormatFromResolution(options.resolution))
    case 'day':
      if (options.xKeyAsRange) {
        const resolutionDayCount = DAY_RESOLUTION_MAP[options.resolution]
        return `Days ${value}-${value + resolutionDayCount - 1}`
      }
      return `Day ${value}`
    default:
      return value
  }
}

export function getCurrencySymbol(currency) {
  return CURRENCY_SYMBOL_MAP[currency] || CURRENCY_SYMBOL_MAP[CURRENCY_DEFAULT]
}

export function getDateRangeDayCount(startDate, endDate, timezone, extendBackend) {
  const thisEndDate = extendBackend ? moment.tz(timezone).endOf('day') : moment.tz(endDate, timezone)
  return thisEndDate.diff(moment.tz(startDate, timezone), 'days')
}

export function isCohortBreakdown(breakdown) {
  if (!breakdown) return false
  return breakdown.field.startsWith(COHORT_BREAKDOWN_KEY)
}

export function getCohortBreakdownResolution(breakdown) {
  if (!breakdown) return null
  if (!isCohortBreakdown(breakdown)) return null
  return parseInt(breakdown.field.split('|')[1])
}

export function disableBreakdownBasedOnCohortAllowance(breakdown, allowCohorts) {
  // Disable breakdown if cohorts are not allowed and the breakdown is a cohort breakdown
  return !allowCohorts && isCohortBreakdown(breakdown)
}